<template>
  <section
    class="relative md:py-24 py-16"
    id="blog"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Event Edukasi & Pemasaran</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Penyelenggaraan event edukasi dan pemasaran yang kami adakan bertujuan untuk memperkenalkan teknologi perekat terbaru. Acara ini akan mencakup sesi presentasi, demonstrasi, dan diskusi interaktif, memberikan wawasan mendalam tentang aplikasi perekat di berbagai industri serta teknik penggunaannya. Selain itu, peserta akan mendapatkan informasi tentang produk-produk kami dan penawaran khusus. Event ini adalah kesempatan sempurna untuk meningkatkan pengetahuan Anda sekaligus menjalin hubungan bisnis yang saling menguntungkan.</p>
      </div><!--end grid-->

      <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
        <div
          v-for="item in blogData"
          :key="item.index"
          class="group relative overflow-hidden"
        >
          <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
            <img
              :src="item.image"
              class="group-hover:scale-110 duration-500"
              alt=""
            >
          </div>

          <div class="mt-6">
            <div class="flex mb-4">
              <span class="flex items-center text-slate-400 text-sm"><i
                  data-feather="calendar"
                  class="size-4 text-slate-900 dark:text-white me-1.5"
                ></i>{{item.date}}</span>
              <span class="flex items-center text-slate-400 text-sm ms-3"><i
                  data-feather="clock"
                  class="size-4 text-slate-900 dark:text-white me-1.5"
                ></i>5 min read</span>
            </div>

            <a
              href=""
              class="title text-lg font-semibold hover:text-teal-500 duration-500 ease-in-out"
            >{{item.title}}</a>
            <p class="text-slate-400 mt-2">{{item.decs}}</p>

            <div class="mt-3">
              <a
                href=""
                class="text-teal-500"
              >Read More <i class="mdi mdi-chevron-right align-middle"></i></a>
            </div>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      blogData: [
        {
          image: require("../assets/images/blog/event1.png"),
          title: "Gathering carta dan ehabond New Fit",
          decs: "Gathering carta dan ehabond New Fit di Serang Banten.",
          date: "5th Oct 2024",
        },
        {
          image: require("../assets/images/blog/event2.png"),
          title: "Gathering carta dan ehabond",
          decs: "Gathering carta dan ehabond di Bogor",
          date: "4th Sept 2023",
        },
        {
          image: require("../assets/images/blog/event3.png"),
          title: "Intimate gathering Bandung dump",
          decs: "Intimate gathering Bandung dump.",
          date: "29th Aug 2023",
        },
      ],
    };
  },
  mounted() {
    feather.replace();
  },
};
</script>