<template>
  <section
    class="relative md:py-24 py-16"
    id="review"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Ragam Produk</h3>

        <p class="text-slate-400 max-w-xl mx-auto">

        </p>
      </div><!--end grid-->

      <div class="grid grid-cols-1 mt-6">
        <div class="tiny-three-item">
          <div
            v-for="item in clientData"
            :key="item.index"
            class="tiny-slide text-center"
          >
            <div class="cursor-e-resize">
              <div class="content relative rounded shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                <i class="mdi mdi-format-quote-open mdi-48px text-teal-500"></i>
                <p class="text-slate-400">{{item.desc}}</p>
                <ul class="list-none mb-0 text-amber-400 mt-3">
                  <li class="inline"><i class="mdi mdi-star"></i></li>
                  <li class="inline"><i class="mdi mdi-star"></i></li>
                  <li class="inline"><i class="mdi mdi-star"></i></li>
                  <li class="inline"><i class="mdi mdi-star"></i></li>
                  <li class="inline"><i class="mdi mdi-star"></i></li>
                </ul>
              </div>

              <div class="text-center mt-5">
                <img
                  :src="item.image"
                  class="size-14 rounded-full shadow-md dark:shadow-gray-700 mx-auto"
                  alt=""
                >
                <h6 class="mt-2 font-semibold">{{item.name}}</h6>
                <span class="text-slate-400 text-sm">{{item.title}}</span>
              </div>
            </div>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>

<script>
import { onMounted } from "vue";
import { tns } from "tiny-slider/src/tiny-slider";

export default {
  data() {
    return {
      clientData: [
        {
          id: 1,
          image: require("../assets/images/team/EB 106.png"),
          // desc: '"Produk ini dirancang khusus untuk industri alas kaki, memberikan daya rekat yang kuat dan ketahanan terhadap kondisi lingkungan. Produk ini digunakan untuk menyatukan berbagai bahan, seperti karet, kulit, dan tekstil, sehingga menghasilkan sepatu yang awet dan nyaman digunakan. Daya rekat yang tinggi sangat penting untuk memastikan bagian-bagian sepatu tetap terjaga, meskipun mengalami tekanan dan gesekan."',
          desc: '"Didesain untuk industri alas kaki, adhesives ini memberikan daya rekat kuat antara bahan seperti karet, kulit, dan tekstil, memastikan sepatu yang tahan lama dan nyaman."',
          name: "FOOTWEAR ADHESIVES",
          title: "Untuk Industri Alas Kaki",
        },
        {
          id: 2,
          image: require("../assets/images/team/PANDA SUPER.png"),
          // desc: '"Produk ini digunakan dalam industri kemasan untuk menyatukan bahan kemasan, seperti karton, plastik, dan kertas. Produk ini dirancang untuk memberikan daya rekat yang cepat dan kuat, serta tahan terhadap berbagai kondisi, termasuk suhu dan kelembapan. Packaging adhesives memastikan kemasan produk tetap aman dan terlindungi selama proses distribusi dan penyimpanan."',
          desc: '"Digunakan dalam kemasan untuk menyatukan karton, plastik, dan kertas. Produk ini menawarkan daya rekat cepat dan kuat, menjaga keamanan produk selama distribusi."',
          name: "PACKAGING ADHESIVES",
          title: "Untuk Industri Kemasan",
        },
        {
          id: 3,
          image: require("../assets/images/team/EB GRIP.png"),
          // desc: '"Produk ini dirancang untuk aplikasi konstruksi, termasuk penyambungan material bangunan seperti kayu, beton, dan metal. Construction adhesives menawarkan daya rekat yang kuat dan tahan lama, serta kemampuan untuk menahan beban berat dan kondisi cuaca ekstrem. Produk ini sering digunakan dalam pembangunan rumah, jembatan, dan struktur lainnya, memastikan kekuatan dan stabilitas bangunan."',
          desc: '"Dirancang untuk aplikasi konstruksi, adhesives ini menyatukan material bangunan seperti kayu dan beton dengan daya rekat yang kuat dan tahan terhadap cuaca ekstrem."',
          name: "CONSTRUCTION ADHESIVES",
          title: "Untuk Konstruksi",
        },
        {
          id: 4,
          image: require("../assets/images/team/EB MERAH.png"),
          // desc: '"Produk ini digunakan untuk menyatukan berbagai komponen dalam industri transportasi, termasuk kendaraan darat, laut, dan udara. Produk ini dirancang untuk memenuhi standar ketahanan dan keamanan yang tinggi, sehingga dapat bertahan dalam kondisi getaran dan tekanan yang ekstrem. Adhesives ini membantu menyatukan bagian-bagian kendaraan, seperti interior, trim, dan komponen struktural."',
          desc: '"Dikhususkan untuk industri transportasi, produk ini menyatukan komponen kendaraan dengan ketahanan tinggi terhadap getaran dan tekanan, memastikan keamanan dan stabilitas."',
          name: "TRANSPORTATION ADHESIVES",
          title: "Untuk Industri Transportasi",
        },
        {
          id: 5,
          image: require("../assets/images/team/EB PVC.png"),
          // desc: '"produk ini ditujukan untuk penggunaan sehari-hari oleh konsumen. Ini termasuk lem untuk keperluan rumah tangga, kerajinan, dan hobi. Produk ini dirancang agar mudah digunakan, aman, dan efektif untuk berbagai aplikasi, mulai dari merekatkan kertas hingga perbaikan kecil di rumah."',
          desc: '"Lem untuk penggunaan sehari-hari, termasuk kerajinan dan perbaikan rumah. Produk ini mudah digunakan dan efektif untuk berbagai aplikasi konsumen."',
          name: "CONSUMER ADHESIVES",
          title: "Untuk Keperluan Rumah tangga, Kerajinan & Hobi",
        },
        {
          id: 6,
          image: require("../assets/images/team/EB ULTRA.png"),
          // desc: '"Produk ini digunakan dalam industri pengolahan kayu untuk menyatukan potongan kayu. Produk ini dirancang untuk memberikan daya rekat yang tinggi dan tahan lama, serta kemampuan untuk mengatasi perubahan suhu dan kelembapan yang dapat memengaruhi kayu. Woodworking adhesives sering digunakan dalam pembuatan furnitur, lantai, dan proyek kayu lainnya."',
          desc: '"Digunakan dalam pengolahan kayu untuk menyatukan potongan kayu dengan daya rekat yang tinggi, ideal untuk pembuatan furnitur dan proyek kayu lainnya."',
          name: "WOODWORKING ADHESIVES",
          title: "Untuk Industri Pengolahan Kayu",
        },
        {
          id: 7,
          image: require("../assets/images/team/SIMPLEX.png"),
          desc: '"Produk karet jadi yang menawarkan fleksibilitas, daya tahan tinggi, dan ketahanan ekstra. Cocok untuk berbagai aplikasi, termasuk alas kaki dan komponen otomotif."',
          name: "SIMPLEX & VIBER",
          title: "Untuk Outsole & Komponen Otomotif",
        },
        {
          id: 8,
          image: require("../assets/images/team/PANDA.png"),
          desc: '"Kategori ini mencakup adhesif untuk aplikasi khusus dalam industri tertentu, memberikan solusi yang tepat untuk kebutuhan unik di berbagai sektor."',
          name: "OTHER",
          title: "Untuk Aplikasi khusus dalam industri tertentu",
        },
      ],
    };
  },
  setup() {
    onMounted(() => {
      tns({
        container: ".tiny-three-item",
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 6000,
        navPosition: "bottom",
        speed: 1000,
        gutter: 12,
        responsive: {
          992: {
            items: 3,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      });
    });
  },
};
</script>