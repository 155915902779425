<template>
    <section class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="services">
        <div class="container relative">
            <div class="grid grid-cols-1 pb-6 text-center">
                <h3 class="font-semibold text-2xl leading-normal mb-4">Pengembangan Produk Baru</h3>

                <p class="text-slate-400 max-w-xl mx-auto">
                    Sepanjang perjalanannya, PT. Sparta Prima terus memperluas produk dengan memanfaatkan teknologi terkini demi kepuasan pelanggan. Diversifikasi produk didorong melalui penelitian, pengembangan, dan sistem pengendalian mutu terpadu. Kami senantiasa berkomitmen pada kualitas, layanan, dan teknologi sebagai pilar utama pertumbuhan.
                </p>
            </div><!--end grid-->

            <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
                <div v-for="item in servicesData" :key="item.id" class="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit">
                    <div class="flex items-center justify-center size-14 -rotate-45 bg-gradient-to-r from-transparent to-teal-500/10 text-teal-500 text-center rounded-full group-hover:bg-teal-500/10 duration-500">
                        <i :data-feather="item.icon" class="size-6 rotate-45"></i>
                    </div>

                    <div class="content mt-6 relative z-1">
                        <a href="" class="title text-lg font-semibold hover:text-teal-500">{{item.title}}</a>
                        <p class="text-slate-400 mt-3">{{item.desc}}</p>

                        <div class="mt-6">
                            <!-- <a href="" class="text-teal-500">Read More <i class="mdi mdi-chevron-right align-middle"></i></a> -->
                        </div>
                    </div>

                    <div class="absolute bottom-0 -end-16">
                        <i :data-feather="item.icon" class="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500"></i>
                    </div>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </section>
</template>

<script>
 import feather from 'feather-icons'
    export default {
        data(){
            return{
                servicesData :[
                    {
                        id:1,
                        icon:'package',
                        title:'Konsep Produk',
                        desc:'Kami merancang produk berdasarkan kebutuhan pelanggan yang di identifikasi melalui pengukuran menggunakan peralatan khusus. Pendekatan ini memastikan setiap produk memenuhi ekspektasi pelanggan dengan fokus pada kualitas dan inovasi.'
                    },
                    {
                        id:2,
                        icon:'trending-up',
                        title:'Penelitian dan Pengembangan',
                        desc:'Kami mengembangkan dan menguji produk baru untuk memastikan kualitas dan relevansi dengan kebutuhan pelanggan. Proses ini melibatkan penelitian dan pengujian menyeluruh untuk mencapai standar yang diinginkan pelanggan kami.'
                    },
                    {
                        id:3,
                        icon:'clipboard',
                        title:'Sample dan Tanggapan',
                        desc:'Kami berkomitmen untuk memberikan pelayanan terbaik dan menghargai umpan balik pelanggan. Sampel produk diuji dan tersedia untuk pelanggan. Umpan balik dianalisis dan ditanggapi cepat oleh tenaga penjual terlatih sebagai bagian dari layanan purna jual kami.'
                        // desc:'Kami berkomitmen untuk memberikan produk berkualitas tinggi melalui proses uji ketat terhadap sampel produk. Sampel ini disediakan bagi pelanggan sebagai bagian dari upaya kami memastikan bahwa produk memenuhi kebutuhan dan harapan mereka.'
                    },
                    {
                        id:4,
                        icon:'tag',
                        title:'Produksi',
                        desc:'Pengendalian mutu kami diterapkan di setiap tahap produksi untuk memastikan bahwa produk yang dihasilkan memenuhi standar tertinggi. Fokus kami adalah menghasilkan produk yang stabil, handal, dan sesuai dengan spesifikasi yang diharapkan pelanggan.'
                        // desc:'Kami sangat menghargai pendapat dan umpan balik dari pelanggan. Setiap masukan dianalisis dengan cermat dan ditanggapi secara cepat oleh tim tenaga penjual yang berpengalaman, sebagai bagian dari layanan purna jual kami, demi meningkatkan kepuasan pelanggan.'
                    },
                    // {
                        // id:5,
                        // icon:'box',
                        // title:'Web Design',
                        // desc:'If the distribution of letters and words is random, the reader will not be distracted from making.'
                    // },
                    // {
                        // id:6,
                        // icon:'camera',
                        // title:'Network Security',
                        // desc:'If the distribution of letters and words is random, the reader will not be distracted from making.'
                    // },
                    // {
                        // id:7,
                        // icon:'bell',
                        // title:'Social Media',
                        // desc:'If the distribution of letters and words is random, the reader will not be distracted from making.'
                    // },
                    // {
                        // id:8,
                        // icon:'monitor',
                        // title:'Design & Branding',
                        // desc:'If the distribution of letters and words is random, the reader will not be distracted from making.'
                    // },
                ]
            }
        },

        mounted() {
        feather.replace();
        }
    
    }
</script>