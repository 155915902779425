<template>
  <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
    <div class="py-[30px] px-0 border-t border-slate-800">
      <div class="container relative text-center">
        <div class="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center">
          <div class="lg:col-span-3 md:text-start text-center">
            <a
              href="#"
              class="text-[22px] focus:outline-none"
            >
              <img
                src="../assets/images/logo-spartaprima.png"
                class="mx-auto md:me-auto md:ms-0"
                alt=""
                style="width: 200px"
              >
            </a>
          </div>

          <div class="lg:col-span-4 text-center mt-6 md:mt-0">
            <p class="text-xs mb-4 lg:mb-0">PT. Sparta Prima © {{date}} 
              <br>Jl. Raya Serang km.13.8 Cikupa, Kab. Tangerang, Indonesia
              <br>
              <br>Telp.&emsp;(021) 624 0067&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;(021) 596 0661
              <br>Fax.&emsp;(021) 639 6113&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;(021) 596 0093</p>
          </div>
          <div></div>
          <div class="lg:col-span-2 md:text-start text-center">
            <img
              src="../assets/images/logo-iso.jpg"
              class="mx-auto md:me-auto md:ms-0 lg:mb-0"
              alt=""
              style="width: 200px"
            >
          </div>

          <ul class="lg:col-span-2 list-none md:text-end text-center mt-6 md:mt-0">
            <li class="inline"><a
                href="https://www.youtube.com/@lemehabond"
                target="_blank"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="youtube"
                  class="size-4 align-middle"
                  title="youtube"
                ></i></a></li>
            <li class="inline"><a
                href="https://www.instagram.com/lem.ehabond/"
                target="_blank"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="instagram"
                  class="size-4 align-middle"
                  title="instagram"
                ></i></a></li>

            <li class="inline"><a
                href="https://tiktok.com/@lemehabond"
                target="_blank"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              >
                <img src="../assets/images/logo-tiktok.png" alt="TikTok" class="size-4 align-middle" />
                </a></li>
              
            <li class="inline"><a
                href="mailto:info@spartaprima.co.id"
                class="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
              ><i
                  data-feather="mail"
                  class="size-4 align-middle"
                  title="email"
                ></i></a></li>
          </ul><!--end icon-->
        </div><!--end grid-->
      </div><!--end container-->
    </div>
  </footer>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  mounted() {
    feather.replace();
  },
};
</script>