<template>
    <section class="realtive md:py-24 py-16" id="services">
        <div class="container relative">
            <div class="grid grid-cols-1 pb-6 text-center">
                <h3 class="font-semibold text-2xl leading-normal mb-4">Inovasi, Kualitas dan Distribusi Terintegrasi</h3>

                <p class="text-slate-400 max-w-xl mx-auto">Kami menggabungkan inovasi di laboratorium, produksi berstandar tinggi, dan distribusi efisien untuk memastikan setiap produk memenuhi kualitas terbaik. Pendekatan terintegrasi ini memungkinkan kami menyediakan solusi perekat yang handal dan tepat waktu bagi berbagai industri.</p>
            </div><!--end grid-->

            <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
                <div class="lg:col-span-4 md:col-span-5">
                    <div class="sticky top-20">
                        <ul class="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                            <li role="presentation">
                                <button class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500" :class="activeindex === '1' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'" @click="tabChange('1')">
                                    <!-- <span class="block">Laboratorium</span> -->
                                    <span class="text-lg mt-2 block">LABORATORIUM</span>
                                    <span class="block mt-2">Berfokus pada penelitian, pengembangan, dan pengujian teknologi perekat dengan standar kualitas tertinggi</span>
                                </button>
                            </li>
                            <li role="presentation">
                                <button class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500 mt-6" :class="activeindex === '2' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'" @click="tabChange('2')">
                                    <!-- <span class="block">Step 2</span> -->
                                    <span class="text-lg mt-2 block">PRODUKSI</span>
                                    <span class="block mt-2">Fasilitas produksi kami dilengkapi dengan teknologi terkini untuk memastikan efisiensi, konsistensi, dan kualitas tertinggi dalam setiap tahap produksi</span>
                                </button>
                            </li>
                            <li role="presentation">
                                <button class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500 mt-6" :class="activeindex === '3' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'" @click="tabChange('3')">
                                    <!-- <span class="block">Step 3</span> -->
                                    <span class="text-lg mt-2 block">GUDANG</span>
                                    <span class="block mt-2">Gudang kami didesain untuk penyimpanan optimal produk jadi, memastikan kualitas lem tetap terjaga sebelum distribusi</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="lg:col-span-8 md:col-span-7">
                    <div id="myTabContent" class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">

                        <div :class="activeindex !== '1' ? 'hidden' : '' " role="tabpanel" aria-labelledby="profile-tab" >
                            <img src="../assets/images/blog/lab-1.png" class="shadow dark:shadow-gray-700 rounded-md" alt="">

                            <div class="mt-6">
                                <h4 class="text-lg font-medium">LABORATORIUM</h4>
                                <p class="text-slate-400 mt-4">Didukung peralatan modern dan tim ahli, kami memastikan setiap produk lem memenuhi ketahanan dan daya rekat yang optimal untuk berbagai industri. Selain itu, laboratorium juga bertanggung jawab atas kontrol kualitas di seluruh proses produksi, memastikan konsistensi dan kepuasan pelanggan.</p>
                                <div class="mt-4">
                                    <a href="" class="text-teal-500">Read More <i class="mdi mdi-chevron-right align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div :class="activeindex !== '2' ? 'hidden' : '' " id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <img src="../assets/images/blog/produksi-1.png" class="shadow dark:shadow-gray-700 rounded-md" alt="">

                            <div class="mt-6">
                                <h5 class="text-lg font-medium">PRODUKSI</h5>
                                <p class="text-slate-400 mt-4">Dengan otomatisasi canggih dan proses yang terstandarisasi, kami mampu memproduksi perekat dan produk karet dalam volume besar tanpa mengorbankan kualitas. Setiap lini produksi diawasi oleh tenaga profesional yang berpengalaman, memastikan produk yang dihasilkan selalu memenuhi standar industri dan kebutuhan pelanggan.</p>
                                <div class="mt-4">
                                    <a href="" class="text-teal-500">Read More <i class="mdi mdi-chevron-right align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div :class="activeindex !== '3' ? 'hidden' : '' " id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <img src="../assets/images/blog/gudang.png" class="shadow dark:shadow-gray-700 rounded-md" alt="">

                            <div class="mt-6">
                                <h5 class="text-lg font-medium">GUDANG</h5>
                                <p class="text-slate-400 mt-4">Dengan sistem manajemen inventaris yang efisien, setiap produk disimpan dalam kondisi yang sesuai untuk menjaga stabilitas dan daya rekat. Proses pengelolaan yang terstruktur memungkinkan kami merespons pesanan dengan cepat, memastikan ketersediaan dan pengiriman tepat waktu ke pelanggan.</p>
                                <div class="mt-4">
                                    <a href="" class="text-teal-500">Read More <i class="mdi mdi-chevron-right align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </section>
</template>


<script>
export default {
    data(){
        return{
            activeindex:"1",
        }
    },

    methods:{
        tabChange(index) {
            this.activeindex = index
        },
    }
}
    

</script>